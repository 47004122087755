import React from "react";

// Colors
import { tertiary } from "./colors";

export const DetermineFooterColor = (slice) => {
	if (slice === "enquiry_form" || slice === "mailchimp") {
		return `#fff`;
	} else {
		return tertiary;
	}
};
